import React from "react"
import { Link } from "gatsby"
import Header from "./header";
// import picture from '/public/picture.jpg'
const ListLink = props => (
  <li >
    <Link className={props.active} to={props.to}>{props.children}</Link>
  </li>
)

export default ({ children, location }) => (
  <React.Fragment>
  <Header location={location}/>
    
    {children}
    <footer >
     <nav>
      <ul>
        <li><a href="https://www.incommincentives.com/FAQs">FREQUENTLY ASKED QUESTIONS</a></li>
        <li><a href="https://www.incommincentives.com/Privacy">PRIVACY POLICY</a></li>
        <li><a href="https://www.incommincentives.com/Terms">TERMS OF USE</a></li>
        <li><a href="https://www.incommincentives.com/PurchaseAgreement">CARD PURCHASE AGREEMENT</a></li>
        
      </ul>
      
      </nav>
      <img src="/images/in-comm-logo-black-90-3@2x.png" alt="IIS Symbol" ></img>
      <p>Copyright @ {(new Date().getFullYear())} InComm All Rights Reserved</p>
        <p>American Express® Reward Cards and American Express®
         Business Gift Cards can be used at U.S. merchants that accept American Express Cards. 
         BUSINESS GIFT CARDS DO NOT HAVE FEES AFTER PURCHASE AND FUNDS DO NOT EXPIRE. 
         REWARD CARDS SOLD MAY HAVE FEES OR THE FUNDS MAY EXPIRE. 
         No ATM cash withdrawal. Additional limitations apply,
          including restriction on use for recurring billing.
          See Cardholder Agreement distributed with the Card for complete details.
           Cards cannot be redeemed for cash, except where required by law. 
           Cards are issued pursuant to a loyalty, reward or other promotional program.
            Cards are issued by American Express Prepaid Card Management Corporation 
            (PCMC). 
            PCMC is licensed as a Money Transmitter by the New York State Department
             of Financial Services. NMLS ID# 913823. Visa® Rewards Cards are issued by
              The Bancorp Bank or MetaBank® pursuant to a license from Visa U.S.A. Inc. 
              The Bancorp Bank and MetaBank; Members FDIC. See card back for issuer. 
              Vanilla Visa Gift Cards and Vanilla Visa Reward Cards are issued by 
              The Bancorp Bank pursuant to a license from Visa U.S.A. Inc. 
              Vanilla MasterCard Gift Cards and Vanilla Mastercard Reward Cards are 
              issued by The Bancorp Bank pursuant to license by Mastercard International
               Incorporated. Mastercard is a registered trademark, and the circles 
               design is a trademark of Mastercard International Incorporated. 
               Vanilla eGift Visa Virtual Accounts are issued by 
               The Bancorp Bank pursuant to a license from Visa U.S.A Inc. 
               Vanilla eReward Visa Virtual Accounts are issued by The Bancorp Bank or 
               MetaBank pursuant to a license from Visa U.S.A. Inc. Certain cards are 
               distributed and serviced by InComm Financial Services, Inc., which is 
               licensed as a Money Transmitter by the New York State Department of 
               Financial Services. Unless otherwise noted in the terms and conditions, 
               Cards may be used in the U.S. and District of Columbia everywhere Visa 
               debit cards or Debit Mastercard are accepted, as applicable. Virtual 
               Accounts can be used online, over the phone, or through mail order only. 
               Terms and conditions apply – see Cardholder Agreement for details.</p>
    </footer>
  </React.Fragment>
) 

    